import axios from 'axios'
import { getAuthHeader } from './config'

const baseURL = 'https://lmsserver-f6a6a46e368d.herokuapp.com/enrollments'

const getEnrollments = async (courseId) => {
  try {
    const response = await axios.get(
      `/${courseId}/enrollments`,
      getAuthHeader()
    )
    console.log(response.data)

    // Check if the user is null in each enrollment
    const enrollments = response.data.map((enrollment) => {
      if (enrollment.user === null) {
        throw new Error('User is null in enrollment')
      }
      return enrollment
    })

    return enrollments
  } catch (error) {
    // Handle the error here
    console.error(error)
    // Return an empty array or throw the error depending on your use case
    return []
  }
}

const updateEnrollment = async (courseId, enrollmentId, enrolledAs) => {
  const response = await axios.post(
    `/${courseId}/enrollments`,
    {
      enrollmentId,
      enrolledAs
    },
    getAuthHeader()
  )
  return response.data
}

const enrollmentsService = {
  getEnrollments,
  updateEnrollment
}
export default enrollmentsService
