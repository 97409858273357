import React from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { useHistory } from 'react-router-dom'; // Import useHistory
import axios from 'axios';

const ForgotPassword = () => {
    const [form] = Form.useForm();
    const history = useHistory(); // Initialize the history object

    const onFinish = async (values) => {
        const { email } = values;

        try {
            // Send a request to the server to initiate the password reset process
            await axios.post('https://lmsserver-f6a6a46e368d.herokuapp.com/users/forgetpassword', {
                email,
            });

            message.success('Password reset email sent successfully');
            
            // Redirect to the login page after sending the reset email
            history.push('/login');
        } catch (error) {
            console.error('Password reset email failed:', error);
            // Handle and display the error to the user if needed
            message.error('Password reset email failed');
        }
    };

    return (
        <Card title="Forgot Password">
            <Form
                form={form}
                name="forgotPasswordForm"
                onFinish={onFinish}
                initialValues={{
                    email: '',
                }}
            >
                <Form.Item
                    name="email"
                    label="Email Address"
                    rules={[
                        {
                            type: 'email',
                            message: 'Please enter a valid email address',
                        },
                        {
                            required: true,
                            message: 'Please enter your email address',
                        },
                    ]}
                >
                    <Input placeholder="Enter your email address" />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8 }}>
                    <Button type="primary" htmlType="submit">
                        Reset Password
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default ForgotPassword;
