export const SET_USER = 'SET_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_FAILURE = 'FORGET_PASSWORD_FAILURE'
export const SAVE_USER_AND_TOKEN = 'SAVE_USER_AND_TOKEN'

export const REGISTER_USER = 'REGISTER_USER'
/* export const registerUser = (userData) => ({
    type: REGISTER_USER,
    payload: userData,
  }); */
