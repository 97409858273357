import axios from 'axios'
import { getAuthHeader } from './config'

export const baseURL = 'https://lmsserver-f6a6a46e368d.herokuapp.com/achievements'

const getAllAchievements = async () => {
  const response = await axios.get(`${baseURL}`, getAuthHeader())
  return response.data
}

const achievementsService = {
  getAllAchievements
}
export default achievementsService
