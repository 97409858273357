import axios from 'axios'
import { getAuthHeader } from './config'

export const baseURL = 'https://lmsserver-f6a6a46e368d.herokuapp.com/deadlines'

const getDeadLines = async () => {
  const response = await axios.get(`${baseURL}`, getAuthHeader())
  return response.data
}

const getDeadLinesCalendar = async () => {
  const response = await axios.get(`${baseURL}/calendar`, getAuthHeader())
  return response.data
}

const deadlinesService = {
  getDeadLines,
  getDeadLinesCalendar
}
export default deadlinesService
