import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import axios from 'axios';

const ResetPassword = () => {
    const { id, token } = useParams();
    const history = useHistory();
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const { password, confirmPassword } = values;

        if (password !== confirmPassword) {
            // Handle password mismatch error (e.g., display a message to the user)
            message.error('Password and Confirm Password do not match');
            return;
        }

        try {
            await axios.post(`https://lmsserver-f6a6a46e368d.herokuapp.com/users/resetpass/${id}/${token}`, {
                password: values.password
            });

            message.success('Password reset successful');
            // Redirect to the login page after successful reset
            history.push('/login');
        } catch (error) {
            console.error('Password reset failed:', error);
            // Handle and display the error to the user if needed
            message.error('Password reset failed');
        }
    };

    return (
        <Card title="Reset Password">
            <Form
                form={form}
                name="resetPasswordForm"
                onFinish={onFinish}
                initialValues={{
                    password: '',
                    confirmPassword: '',
                }}
            >
                <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter a new password',
                        },
                    ]}
                >
                    <Input.Password placeholder="Enter your new password" />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Passwords do not match');
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Confirm your new password" />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8 }}>
                    <Button type="primary" htmlType="submit">
                        Update
                    </Button>
                </Form.Item>
            </Form>
            <p>
                <Link to="/login">Return to Login</Link>
            </p>
        </Card>
    );
};

export default ResetPassword;
