import React, { useState, useEffect } from 'react'
import { Layout, Menu, Breadcrumb, Typography, Table } from 'antd'
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getAllParticipants,
  updatePrivilege
} from '../../reducers/participantsReducer'
import Spinner from '../../components/Spinner'

const { DateTime } = require('luxon')

const { SubMenu } = Menu
const { Header, Content, Sider } = Layout
const { Title, Text } = Typography
const { Column } = Table

const AdminDashboard = () => {
  const dispatch = useDispatch()
  const { courseId } = useParams()
  const [users, setUsers] = useState([])
  const participants = useSelector((state) => state.courseParticipants.data)
  const loading = useSelector((state) => state.courseParticipants.loading)
  const user = useSelector((state) => state.auth.user)
  const courses = useSelector((state) => state.courses.data)

  const course = courses.find((course) => course.id === courseId)

  useEffect(() => {
    dispatch(getAllParticipants(courseId))
  }, [courseId, dispatch])

  if (loading) return <Spinner size="large" />

  return (
    <Layout>
      <Header className="header">
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
          <Menu.Item key="1">Dashboard</Menu.Item>
          <Menu.Item key="2">Users</Menu.Item>
          <Menu.Item key="3">Settings</Menu.Item>
        </Menu>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{ height: '100%', borderRight: 0 }}
          >
            <SubMenu key="sub1" icon={<UserOutlined />} title="User Management">
              <Menu.Item key="1">Users</Menu.Item>
              <Menu.Item key="2">Roles</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub2"
              icon={<LaptopOutlined />}
              title="Device Management"
            >
              <Menu.Item key="3">Devices</Menu.Item>
              <Menu.Item key="4">Categories</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub3"
              icon={<NotificationOutlined />}
              title="Notifications"
            >
              <Menu.Item key="5">Emails</Menu.Item>
              <Menu.Item key="6">SMS</Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Admin</Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280
            }}
          >
            Admin dashboard content
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default AdminDashboard
