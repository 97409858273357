import React from 'react';
import { List, Space, Button } from 'antd';
import styled from 'styled-components';
import { FileOutlined, YoutubeOutlined, DeleteOutlined, TeamOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router';

const HoverableListItem = styled(List.Item)`
  cursor: pointer;
  padding-left: 8px;
  font-size: 16px;
  &:hover {
    background-color: #f0f0f0;
    color: #1890ff;
  }
`;

const getIcon = (item) => {
  if (item.type === 'video') return <YoutubeOutlined />;
  if (item.type === 'file') return <FileOutlined />;
  if (item.type === 'teams') return <TeamOutlined />;
};

const ModuleItem = ({ item, instructorAccess, removeModuleItem }) => {
  const { title, type, url } = item;

  const { courseId } = useParams();
  const history = useHistory();

  const getActions = (item) => {
    if (instructorAccess) {
      return (
        <span
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Button
            onClick={() => removeModuleItem(item)}
            type="text"
            icon={<DeleteOutlined />}
            danger
          />
        </span>
      );
    }
    return null;
  };

  // const handleClick = () => {
  //   if (type === 'file') {
  //     // You can modify this logic based on how you want to handle files
  //     // For now, it opens the file in a new tab/window
  //     window.open(url, '_blank');
  //   } else if (type === 'teams') {
  //     // Handle Microsoft Teams link action here
  //     window.open(url, '_blank');
  //   } else {
  //     history.push(`/app/course/${courseId}/lectures/${item.id}`);
  //   }
  // };

  const handleClick = () => {
    if (type === 'file') {
      window.open(url, '_parent')
    } else if (type === 'link') {
      window.open(url, '_blank')
    } else if (type === 'h5p') {
      // alert("H5p interactive video comming soon!")
      window.open(
      embedH5P('617', 1091, 675), '_parent')
    
    } else {
      history.push(`/app/course/${courseId}/lectures/${item.id}`)
    }
  }
  

  function embedH5P(contentId, width, height, allowFullscreen = false) {
    const container = document.getElementById('root');
  
    if (container) {
      const h5pParameters = {
        width: width || 1091,
        height: height || 675,
        frameborder: 0,
        // allowfullscreen: allowFullscreen ? 'allowfullscreen' : '',
        allow: 'geolocation *; microphone *; camera *; midi *; encrypted-media *',
        title: 'Interactive Video',
      };
  
      // Create an iframe element
      const iframe = document.createElement('iframe');
      iframe.src = `https://h5p.org/h5p/embed/${contentId}`;
      iframe.width = h5pParameters.width;
      iframe.height = h5pParameters.height;
      iframe.frameborder = h5pParameters.frameborder;
      // iframe.allowfullscreen = h5pParameters.allowfullscreen;
      iframe.allow = h5pParameters.allow;
      iframe.title = h5pParameters.title;
  
      // Append the iframe to the container
      container.innerHTML = ''; // Clear the container before embedding a new video
      container.appendChild(iframe);
  
      // Include the H5P resizer script
      const resizerScript = document.createElement('script');
      resizerScript.src = 'https://h5p.org/sites/all/modules/h5p/library/js/h5p-resizer.js';
      resizerScript.charset = 'UTF-8';
  
      // Append the resizer script to the document
      document.head.appendChild(resizerScript);
    } else {
      console.error('H5P container not found');
    }
  }
  
  // Example usage:
  // Replace '617' with the actual content ID of your H5P content
  // embedH5P('617', 1091, 675);

  return (
    <HoverableListItem extra={getActions(item)} onClick={handleClick}>
      <Space size={20}>
        {getIcon(item)}
        {title}
      </Space>
    </HoverableListItem>
  );
};

export default ModuleItem;
