import usersService from '../services/users'
import {
  SET_USER,
  CLEAR_USER,
  UPDATE_USER,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  REGISTER_USER
} from '../actions/auth'
import { notification, message } from 'antd' // Added missing imports

import subscribeUser from './../subscription'

// manpulates the data coming from backend
const adapterFunc = (user) => {
  return { ...user.user, token: user.token }
}

let user = window.localStorage.getItem('eduhub-user')
const intialState = user
  ? { user: adapterFunc(JSON.parse(user)), isAuth: true }
  : { user: null, isAuth: false }

const authReducer = (state = intialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { user: adapterFunc(action.user), isAuth: true }
    case UPDATE_USER:
      return { user: adapterFunc(action.user), isAuth: true }
    case CLEAR_USER:
      return { user: null, isAuth: false }
    case FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null
      }
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null
      }
    case FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error
      }
    case REGISTER_USER:
      return {
        user: adapterFunc(action.payload.user),
        isAuth: true,
        token: action.payload.token
      }
    default:
      return state
  }
}

export const editProfile = (user) => {
  return async (dispatch) => {
    try {
      const response = await usersService.update(user)
      console.log(response)
      if (response) {
        window.localStorage.setItem('eduhub-user', JSON.stringify(response))
        dispatch({ type: UPDATE_USER, user: response })
        notification.success({
          message: 'Saved Successfully'
        })
      } else {
        notification.error({
          message: 'Cant Save'
        })
      }
    } catch (error) {
      notification.error({
        message: 'Cant Save'
      })
      console.log(error)
    }
  }
}

export const register = (credentials) => {
  return async (dispatch) => {
    try {
      const response = await usersService.register(credentials)

      if (response?.user && response.token) {
        dispatch({
          type: REGISTER_USER,
          payload: { user: response.user, token: response.token }
        })

        /* //save the user and token to localStorag
        window.localStorage.setItem(
          'eduhub-user',
          JSON.stringify(response.user)
        )
        window.localStorage.setItem('eduhub-token', response.token) */

        message.success(
          `Congratulations on the successful registration: ${
            response?.user ? response.user.name : 'Student'
          }`
        )
      } else {
        message.error('Registration failed')
      }
    } catch (error) {
      message.error('Invalid credentials')
      console.log(error)
    }
  }
}

/* actions for authentication bellow */

export const login = (credentials) => {
  return async (dispatch) => {
    try {
      const response = await usersService.login(credentials)
      window.localStorage.setItem('eduhub-user', JSON.stringify(response))
      subscribeUser()
      dispatch({ type: SET_USER, user: response })
      console.log('subscribed')
    } catch (error) {
      console.log(error)
      // the backend should send the error message to show
      console.log(error)
      message.error('incorrect UserName Or Password')
    }
  }
}

export const logout = () => {
  return async (dispatch) => {
    try {
      await usersService.logout()
      dispatch({ type: CLEAR_USER })
      window.localStorage.removeItem('eduhub-user')
    } catch (error) {
      console.log(error)
    }
  }
}

export const forgetPassword = (email) => {
  return async (dispatch) => {
    try {
      // Dispatch a request action
      dispatch({ type: FORGET_PASSWORD_REQUEST })

      // Send a request to the server to initiate the password reset
      const response = await usersService.forgotPassword(email)

      if (response.status === 'success') {
        // Display a success message to the user
        message.success('Password reset email sent successfully')

        // Dispatch a success action with the response as payload
        dispatch({ type: FORGET_PASSWORD_SUCCESS, payload: response })
      } else {
        // Display an error message based on the response from the server
        if (response.message) {
          message.error('Password reset email failed: ' + response.message)
        } else {
          message.error('Password reset email failed')
        }

        // Dispatch a failure action with the error message
        dispatch({ type: FORGET_PASSWORD_FAILURE, payload: response.message })
      }
    } catch (error) {
      // Dispatch a failure action in case of an error
      message.error('An error occurred while processing your request')
      console.error(error)

      // You can include the error message in the payload if it's available
      dispatch({
        type: FORGET_PASSWORD_FAILURE,
        payload:
          error.message || 'An error occurred while processing your request'
      })
    }
  }
}

export default authReducer
