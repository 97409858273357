import { Typography, Table, Avatar, Button, Upload, message,Input } from 'antd';
import { DateTime } from 'luxon';
import { InboxOutlined } from '@ant-design/icons'; 
import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { getAllAchievements } from '../../reducers/achievementsReducer';
import { FlexSectionHeader } from '../style';
import axios from 'axios';
const AWS = require('aws-sdk');

const { Title } = Typography;
const { Column } = Table;
const { Dragger } = Upload;

const Achievements = (props) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const achievements = useSelector((state) => state.achievements.data);
  const loading = useSelector((state) => state.achievements.loading);
  const userRole = useSelector((state) => state.auth.user.role);
  const [selectedFiles, setSelectedFiles] = useState({});
  useEffect(() => {
    dispatch(getAllAchievements());
  }, []);

  if (loading) return <Spinner size="large" />;
  
  const handleFileUpload = async (file, achievementId, onSuccess) => {
    try {
      const formData = new FormData();
      formData.append('certificate', file);
  
      const response = await axios.post(
        `https://lmsserver-f6a6a46e368d.herokuapp.com/achievements/${achievementId}/update-certificateFile`,
        formData
      );
  
      if (response.data.message) {
        message.success(response.data.message);
        onSuccess();
      } else {
        message.error('Failed to update certificate');
      }
    } catch (error) {
      console.error('Error updating certificate:', error);
      message.error('Error updating certificate');
    } finally {
      // Only reset selected files if the request was successful
      if (onSuccess) {
        setSelectedFiles((prevSelectedFiles) => ({
          ...prevSelectedFiles,
          [achievementId]: null,
        }));
      }
    }
  };
  
  
  const s3 = new AWS.S3({
    accessKeyId: 'AKIAUYA3SZFL6NF66YVK',
    secretAccessKey: '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',
  });
  
  const handleURLUpload = async (url, achievementId, onSuccess) => {
    try {
      const formData = new FormData();
      formData.append('certificate', url);
  
      // Upload to S3
      const s3Params = {
        Bucket: 'vusi-lms-uploads',
        Key: `certificates/${achievementId}`, // Define your own key structure
        Body: formData.getBuffer(), // Assuming `getBuffer()` returns the certificate content as Buffer
        ContentType: 'application/pdf', // Adjust content type based on your certificate type
      };
  
      // Upload to S3
      await s3.upload(s3Params).promise();
  
      // Update certificate URL in your backend
      const response = await axios.post(
        `https://lmsserver-f6a6a46e368d.herokuapp.com/achievements/${achievementId}/update-certificateUrl`,
        formData
      );
  
      if (response.status === 200 && response.data.message) {
        // Only consider it successful if the status code is 200 and there is a success message
        message.success(response.data.message);
        onSuccess && onSuccess();
      } else {
        // Display an error message if the status code is not 200 or if there is no success message
        message.error('Failed to update certificate');
      }
    } catch (error) {
      console.error('Error updating certificate:', error);
      // Display a generic error message when an exception occurs
      message.error('Error updating certificate');
    } finally {
      // Only reset selected files if the request was successful
      if (onSuccess) {
        setSelectedFiles((prevSelectedFiles) => ({
          ...prevSelectedFiles,
          [achievementId]: null,
        }));
      }
    }
  };
  
  return (
    <>
      <Title level={3}>Achievements</Title>

      <Table
        pagination={false}
        style={{ marginTop: '24px' }}
        rowKey={(achievement) => achievement.id}
        dataSource={achievements}
      >
        <Column
          title="Photo"
          dataIndex={['user', 'photo']}
          render={(img) => <Avatar src={img} />}
        />
        <Column title="Course Name" dataIndex={['course', 'name']} />
        <Column
          title="Finished At"
          dataIndex="finishedAt"
          render={(time) => {
            if (!time) return '-';
            else
              return DateTime.fromISO(time).toLocaleString(
                DateTime.DATETIME_MED
              );
          }}
        />
        <Column
          title="Grade"
          dataIndex="gradeLetter"
          render={(grade) => {
            if (!grade) return '-';
            else return grade;
          }}
        />
        <Column
          title="Certificate"
          dataIndex="certificate"
          render={(certificate) => {
            if (!certificate) return '-';
            else return <a href={`${certificate}`}>certificate</a>;
          }}
        />
       {userRole === 'instructor' || userRole === 'admin' ? (
  <>
   <Column
  title="Upload Certificate"
  dataIndex="uploadCertificate"
  render={(text, record) => (
    <div>
      <Dragger
        name="file"
        multiple={false}
        showUploadList={false}
        beforeUpload={(file) => {
          // Set the selected file for the specific record when a file is chosen
          setSelectedFiles((prevSelectedFiles) => ({
            ...prevSelectedFiles,
            [record.id]: file,
          }));
          return false; // Prevent automatic file upload
        }}
        customRequest={({ file, onSuccess }) => {
          // Handle file upload
          handleFileUpload (file, record.id, onSuccess);
        }}
      >
        {selectedFiles[record.id] && (
          <div>
            <p style={{ marginBottom: '8px' }}>
              Selected File: {selectedFiles[record.id].name}
            </p>
          </div>
        )}
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text" style={{ marginBottom: '8px' }}> 
          Click or drag file to this area to upload
        </p>
      </Dragger>

      {selectedFiles[record.id] && (
        <Button
          type="primary"
          style={{ marginTop: '8px' }}  // Add marginTop here
          onClick={() => {
            const file = selectedFiles[record.id];
            if (file) {
              handleFileUpload (file, record.id, () => {});
            }
          }}
        >
          Upload
        </Button>
      )}
    </div>
  )}
/>
    <Column
  title="Enter Certificate URL"
  dataIndex="enterCertificateUrl"
  render={(text, record) => (
    <>
      {/* Input for entering Certificate URL */}
      <Input
        id={`certificateUrlInput_${record.id}`}
        placeholder="Enter Certificate URL"
        style={{ marginTop: '16px', marginBottom: '8px' }}  // Add marginBottom here
        onClick={(e) => {
          // Stop the propagation of the click event on the input
          e.stopPropagation();
        }}
      />

      {/* Submit button for sending URL to the server */}
      <Button
        type="primary"
        onClick={(e) => {
          e.stopPropagation();
          const certificateUrlInput = document.getElementById(
            `certificateUrlInput_${record.id}`
          );
          const certificateUrl = certificateUrlInput.value.trim();

          // Only handle the upload if not already uploading
          if (!uploading) {
            setUploading(true);
            handleURLUpload(certificateUrl, record.id);
            certificateUrlInput.value = ''; // Clear the input field
          }
        }}
      >
        Submit
      </Button>
    </>
  )}
/>
  </>
) : null}

      </Table>
    </>
  );
};

export default Achievements;
